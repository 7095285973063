@charset "utf-8";

/*
  Bulma Default SASS Variable Overrides
  https://bulma.io/documentation/customize/variables/
 */

// Colors
$beige-lighter: #eff0eb;

// Global
$body-background-color: $beige-lighter;
$info: #2ac8da;

// Navbar
$navbar-background-color: #2ac8da;
$navbar-item-color: white;

// Cards
$card-content-padding: 0.75em;
$card-footer-padding: 1em;
$card-header-padding: 0.75rem 0.75rem;

// Tabs
$tabs-toggle-link-active-background-color: $info;
$tabs-toggle-link-active-border-color: $info;

$dropdown-menu-min-width: 1rem;

@import '~bulma/bulma.sass';

/*
  Bulma CSS Class Overrides
 */

.container {
    margin-top: 60px;
    margin-bottom: 60px;
}

.link {
    display: flex;
}

.navbar {
    z-index: 1000;
}

.navbar-title {
    margin-left: 10px;
}

.pagination-button {
    margin: 10px;
}

.dropdown-content {
    max-height: 16em;
    overflow: auto;
}

.tabs ul {
    flex-shrink: 1;
    flex-wrap: wrap;
    border-bottom-color: transparent;
}

// TUI Editor View Overrides
.tui-editor-contents table th {
    background-color: transparent !important;
    font-weight: 700 !important;
}

.tui-editor-contents table td {
    border: 1px solid #aaa !important;
    font-size: 1rem !important;
}

.tui-editor-contents table {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    border: 1px solid #aaa !important;
}

.tui-editor-contents table th {
    color: black !important;
    font-size: 1.25rem !important;
}

.tui-editor-contents * {
    font-size: 1rem !important;
}

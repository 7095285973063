.Categories {
    display: inline;
}

.Tags {
    display: flex;
    flex-direction: row;

    &:last-child {
        margin-bottom: 0;
    }
}

.PreviewMode {
    overflow: hidden;
    max-height: 100px;
}

.Category {
    border: 0;
    background-color: white;
    padding: 0;
    color: #3273dc;

    &:hover {
        cursor: pointer;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-7-7 15:42:28
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-bck {
    0% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}
@keyframes swing-in-top-bck {
    0% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

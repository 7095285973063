.PossibleAnswers {
    margin-bottom: 15px;

    &_answer {
        display: flex;
    }

    &_answerInput {
        margin-right: 12px;
    }
}

.PossibleAnswerRow {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    word-break: break-all;

    &_button {
        min-width: 64px;
        margin-right: 12px;
    }
}

.CreatedQuizzes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.UpdateBanner {
    margin: 10px;
}

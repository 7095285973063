.TitleAnim {
    margin-bottom: 60px;
    text-align: center;
    -webkit-animation: swingInTopForward 0.6s
        cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation: swingInTopForward 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        both;
}

hr {
    border: 1px solid #ccc;
}

@-webkit-keyframes swingInTopForward {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}
@keyframes swingInTopForward {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

.WordOfTheDaySection {
    margin-bottom: 40px;
    min-height: 260px;
}

.CategorySection {
    margin-bottom: 40px;
    min-height: 140px;

    > div {
        padding: 0.75rem;
        display: flex;
        flex-wrap: wrap;
    }
}

.TagSection {
    padding: 0.75rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;

    > p:not(:last-child) {
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
    }
}

.TwitterIcon {
    background-color: #1da1f3;

    img {
        color: white;
        fill: white;
    }
}

.FacebookIcon {
    background-color: #3b5998;

    img {
        color: white;
        fill: white;
    }
}
